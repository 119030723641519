/* colors */
$colorALangPrimary: #4BB227;
$colorALangAccent: #106BC6;
$colorALangTextDark: #323232;
$colorALangTextLight: #666666;

/* dimensions */
$mobileBreakpoint: 768px;

/* fonts */
@font-face {
  font-family: 'VDS';
  src: url('/fonts/vds.woff2') format('woff2'),
  url('/fonts/vds.woff') format('woff'),
  url('/fonts/vds.ttf') format('truetype');
}

/* only the needed components of spectre */
/* have a look at spectre.css/src/spectre to get an overview */
$primary-color: $colorALangPrimary;
$secondary-color: $colorALangAccent;
@import "../../node_modules/spectre.css/src/variables";
@import "../../node_modules/spectre.css/src/mixins";
@import "../../node_modules/spectre.css/src/normalize";
@import "../../node_modules/spectre.css/src/base";
@import "../../node_modules/spectre.css/src/typography";
@import "../../node_modules/spectre.css/src/buttons";
@import "../../node_modules/spectre.css/src/forms";
@import "../../node_modules/spectre.css/src/layout";
@import "../../node_modules/spectre.css/src/cards";
@import "../../node_modules/spectre.css/src/chips";
@import "../../node_modules/spectre.css/src/empty";
@import "../../node_modules/spectre.css/src/utilities";

.btn-accent {
  @include button-variant($colorALangAccent);
}

/* layout */
.split-container {

  width: 100vw;
  height: 100vh;
  display: flex;
  @media (max-width: $mobileBreakpoint) {
    height: auto;
    flex-wrap: wrap;
  }
  @media print {
    width: inherit;
    height: inherit;
  }

  .split-container-image {
    width: 40%;
    height: 100vh;
    background-image: url("/img/alang_1440x2160.jpg");
    background-size: cover;
    @media (max-width: $mobileBreakpoint) {
      height: 65vh;
      width: 100%;
    }
    @media (min-width: 300px) and (max-width: $mobileBreakpoint) {
      max-height: 300px;
      max-width: 300px;
      margin: 0 auto;
    }
    @media print {
      width: 216px;
      height: 324px;
    }
  }

  .split-container-content {
    width: 60%;
    min-height: 100vh;
    overflow: auto;
    padding: 1em 2em;
    @media (max-width: $mobileBreakpoint) {
      width: 100%;
      height: auto;
    }

    .split-container-content-inner {
      max-width: 1024px;
      margin: 0 auto;
    }
  }
}

/* header */
header {

  .logo {
    float: right;
    @media (max-width: $mobileBreakpoint) {
      display: none;
    }
  }

  .name:hover {
    text-decoration: none;
  }

  $sizeName: 2em;
  @media (max-width: $mobileBreakpoint) {
    $sizeName: 1.5em;
  }
  $fontName: VDS, Helvetica, sans-serif;
  .firstname {
    color: $colorALangTextDark;
    font-family: $fontName;
    font-size: $sizeName;
    margin-right: .2em;
  }
  .lastname {
    color: $colorALangTextLight;
    font-family: $fontName;
    font-size: $sizeName;
  }

  .title {
    color: $colorALangPrimary;
    font-family: Arial, Helvetica, sans-serif;
  }
}

/* footer */
footer {
  margin-top: 2em;
  border-top: 1px solid #e6e6e6;
  padding-top: .5em;
  font-size: .8em;
  color: gray;
}

/* index.html  */
$marginSections: 3.5em;

section.about {
  margin-top: $marginSections;
}

section.contact {
  margin-top: $marginSections;
  @media print {
    display: none;
  }
}

div.skills {
  margin-top: 2em;
  font-weight: bold;
  text-align: center;
}

/* legal-notice.html */
main.legal-notice {
  h4 {
    margin-top: 1em;
  }
  h5 {
    margin-top: .5em;
  }
}
